// src/components/shared/MenuItem.jsx

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MenuItem = ({ to, icon, label }) => {
  const location = useLocation();

  // Check if the menu item is active
  const isActive = location.pathname === to;

  return (
    <li>
      <Link
        to={to}
        aria-label={label}
        className={`text-base flex items-center justify-center sm:justify-start p-4 rounded-lg transition-all ${
          isActive ? 'text-yellow-400' : 'text-white hover:text-yellow-400'
        }`}
      >
        <FontAwesomeIcon
          icon={icon}
          className={`w-6 h-6 ${isActive ? 'text-yellow-400' : 'text-white hover:text-yellow-400'}`}
        />
        <span className="ml-3 hidden sm:inline text-left">{label}</span>
      </Link>
    </li>
  );
};

export default MenuItem;
