// src/axiosConfig.js
import axios from 'axios';

// Set the base URL based on the environment
const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? 'https://api.monchart.com'
  : 'http://localhost:3000';

// Create an axios instance with default settings
const axiosInstance = axios.create({
  baseURL: API_BASE_URL, // Base URL for API requests
  withCredentials: true, // Include cookies in requests to support authentication
  headers: {
    'Content-Type': 'application/json', // Set default content type
  },
});

// Helper function to check if the user is authenticated
export const isAuthenticated = async () => {
  try {
    const response = await axiosInstance.get('/api/auth/verify'); // Endpoint for token verification
    return response.status === 200; // Returns true if verification is successful (status 200)
  } catch (error) {
    console.error('Authentication verification failed:', error);
    return false;
  }
};

// Global request interceptor to log request details
axiosInstance.interceptors.request.use(
  (config) => {
    console.log(`Making request to ${config.baseURL}${config.url}`);
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Global response interceptor to handle errors uniformly
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 401:
          console.error('Unauthorized access - possibly due to expired session.');
          // Optional: Redirect to login or trigger logout if session has expired
          // window.location.href = '/login';
          break;
        case 403:
          console.error('Forbidden - you do not have access to this resource.');
          break;
        case 404:
          console.error('Resource not found - check URL or endpoint.');
          break;
        case 405:
          console.error('Method Not Allowed - check HTTP method or endpoint availability.');
          break;
        case 500:
          console.error('Internal Server Error - please try again later.');
          break;
        default:
          console.error(`Server returned status code ${status} - please try again.`);
      }
    } else {
      console.error('Network error or server unreachable:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
