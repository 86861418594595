// src/context/AuthContext.js

import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [preferredTheme, setPreferredTheme] = useState('light');
    const [preferredLanguage, setPreferredLanguage] = useState('en');

    // Function to fetch user data, including theme and language preferences
    const fetchUser = useCallback(async (retryCount = 3) => {
        try {
            const response = await axios.get('/api/auth/profile', {
                withCredentials: true, // Ensure cookies are included
            });
            const userData = response.data.data;
            setUser(userData);
            setPreferredTheme(userData.preferredTheme || 'light');
            setPreferredLanguage(userData.preferredLanguage || 'en');
            document.documentElement.setAttribute('data-theme', userData.preferredTheme || 'light');
        } catch (error) {
            console.error('Error fetching profile:', error);
            if (retryCount > 0) {
                setTimeout(() => fetchUser(retryCount - 1), 1000);
            } else {
                setUser(null);
                if (error.response && error.response.status === 401) {
                    // Optional: Handle unauthorized access
                    console.warn("Unauthorized access - redirecting to login");
                }
            }
        } finally {
            setLoading(false);
        }
    }, []);

    // Handle user login and save auth token
    const handleLogin = async (email, password) => {
        setLoading(true);
        try {
            const response = await axios.post(
                '/api/auth/login',
                { email, password },
                { withCredentials: true } // Ensure cookies are included
            );
            if (response.data.success) {
                await fetchUser(); // Fetch user data to set context state after successful login
            }
        } catch (error) {
            console.error('Login error:', error);
            setUser(null);
            if (error.response && error.response.status === 401) {
                console.warn("Invalid login credentials.");
            }
        } finally {
            setLoading(false);
        }
    };

    // Handle user logout and clear auth token
    const handleLogout = async () => {
        try {
            await axios.post('/api/auth/logout', {}, { withCredentials: true });
            setUser(null);
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    // Update preferred theme in backend and apply it immediately
    const updatePreferredTheme = async (theme) => {
        try {
            await axios.put(
                '/api/auth/profile/theme',
                { preferredTheme: theme },
                { withCredentials: true }
            );
            setPreferredTheme(theme);
            document.documentElement.setAttribute('data-theme', theme);
        } catch (error) {
            console.error('Failed to update theme preference:', error);
        }
    };

    // Update preferred language in backend and apply it immediately
    const updatePreferredLanguage = async (language) => {
        try {
            await axios.put(
                '/api/auth/profile/language',
                { preferredLanguage: language },
                { withCredentials: true }
            );
            setPreferredLanguage(language);
        } catch (error) {
            console.error('Failed to update language preference:', error);
        }
    };

    // Initial fetch of user data on load
    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                fetchUser,
                handleLogin,
                handleLogout,
                loading,
                preferredTheme,
                preferredLanguage,
                updatePreferredTheme,
                updatePreferredLanguage,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;