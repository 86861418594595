import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      upload: "Upload",
      dashboard: "Dashboard",
      profile: "Profile",
      payments: "Pricing",
      home: "Home",
      charts: "Charts",
      settings: "Settings",
      support: "Support",
      clean_data: "Clean", // Translation for Clean Data page
      // Not Found Page
      notFoundTitle: "Oops, you're in luck! 🎉",
      notFoundMessage: "It looks like you've stumbled upon a page that doesn't exist. But no worries, luck is on your side!",
      notFoundSubMessage: "Click the button below to quickly return to the main page before the internet monsters get you!",
      notFoundButton: "Back to Main Page",
      // MainIntro Page
      mainIntroWelcome: "MonChart",
      mainIntroGoTo: "The ultimate solution for instant charting and analytics",
      mainIntroInstructions: "Just upload your file, and watch your data charts",
      loadingEmailSubscription: "Loading email subscription...",
      loadingChart: "Loading chart...",
      // New Instructions Section
      uploadInstructions: "Simply drag & drop your CSV file, and your customized charts will appear instantly. Start analyzing your data today!",
      chartSwitcherLabel: "Switch between charts to view your data in different visualizations:",
      barChart: "Bar",
      lineChart: "Line",
      doughnutChart: "Doughnut",
      radarChart: "Radar",
      pricingButton: "Explore Pricing",
      moreCharts: "More",
    },
  },
  ru: {
    translation: {
      upload: "Загрузить",
      dashboard: "Панель управления",
      profile: "Профиль",
      payments: "Платежи",
      home: "Главная",
      charts: "Графики",
      settings: "Настройки",
      support: "Поддержка",
      clean_data: "Очистка", // Translation for Clean Data page in Russian
      // Not Found Page
      notFoundTitle: "Ой, вам повезло! 🎉",
      notFoundMessage: "Похоже, вы попали на несуществующую страницу. Но не волнуйтесь, удача на вашей стороне!",
      notFoundSubMessage: "Быстро нажмите на кнопку ниже, чтобы вернуться на главную страницу, прежде чем вас найдут интернет-монстры!",
      notFoundButton: "Назад на главную страницу",
      // MainIntro Page
      mainIntroWelcome: "MonChart",
      mainIntroGoTo: "Лучшее решение для мгновенных графиков и аналитики",
      mainIntroInstructions: "Просто загрузите ваш файл и наблюдайте, как ваши данные преобразуются в графики",
      loadingEmailSubscription: "Загрузка подписки на электронную почту...",
      loadingChart: "Загрузка графика...",
      // New Instructions Section
      uploadInstructions: "Просто перетащите ваш CSV файл, и ваши графики сразу появятся. Начните анализировать ваши данные уже сегодня!",
      chartSwitcherLabel: "Переключайтесь между графиками, чтобы видеть ваши данные в разных визуализациях:",
      barChart: "Гистограмма",
      lineChart: "Линейный график",
      doughnutChart: "Кольцевая диаграмма",
      radarChart: "Радарный график",
      pricingButton: "Изучить цены",
      moreCharts: "Больше графиков",
    },
  },
};

i18n
  .use(LanguageDetector) // Detects the user's language
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources,
    fallbackLng: 'en', // Use English if no other language is detected
    debug: true,
    interpolation: {
      escapeValue: false, // React already escapes values
    }
  });

export default i18n;
