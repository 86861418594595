// src/components/shared/SideMenu.jsx

import React, { useState, useEffect } from 'react';
import { faCloudArrowUp, faChartPie, faHouse, faCreditCard, faLifeRing, faBroom } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import MenuItem from './MenuItem';

const SideMenu = () => {
  const { t } = useTranslation();
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  // Toggle menu visibility based on screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMenuVisible(window.innerWidth >= 640);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => window.removeEventListener('resize', handleResize); // Cleanup listener on unmount
  }, []);

  return (
    <div className={`flex flex-col min-h-screen ${isMenuVisible ? 'block' : 'hidden'} sm:block`}>
      <nav className="bg-gradient-indigo-purple text-white flex flex-col flex-grow p-4 shadow-effect h-full w-16 sm:w-48 md:w-56 lg:w-64">
        <ul className="space-y-4 flex-grow">
          <MenuItem to="/" icon={faHouse} label={t('home')} />
          <MenuItem to="/upload" icon={faCloudArrowUp} label={t('upload')} />
          <MenuItem to="/clean" icon={faBroom} label={t('clean_data')} />
          <MenuItem to="/dashboard" icon={faChartPie} label={t('charts')} />
          <MenuItem to="/pricing" icon={faCreditCard} label={t('payments')} />
          <MenuItem to="/support" icon={faLifeRing} label={t('support')} />
        </ul>
      </nav>
    </div>
  );
};

export default SideMenu;
