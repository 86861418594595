// src/App.js

import React, { useEffect, lazy, Suspense, useState } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LoadingSpinner from './components/shared/LoadingSpinner';
import SideMenu from './components/shared/SideMenu';
import './config/i18n';
import AuthProvider from './context/AuthContext';

// Lazy load components for better performance
const Header = lazy(() => import('./components/shared/Header'));
const Homepage = lazy(() => import('./pages/Homepage'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const TrialPage = lazy(() => import('./pages/TrialPage'));
const SupportPage = lazy(() => import('./pages/SupportPage'));
const Footer = lazy(() => import('./components/shared/Footer'));
const CookieConsent = lazy(() => import('./components/shared/CookieConsent'));
const NotFound = lazy(() => import('./components/shared/NotFound'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./pages/TermsOfUse'));
const UploadPage = lazy(() => import('./pages/UploadPage'));
const CleanDataPage = lazy(() => import('./pages/CleanDataPage'));
const LoginSignup = lazy(() => import('./pages/LoginSignup'));
const CancelPage = lazy(() => import('./pages/CancelPage'));
const SuccessPage = lazy(() => import('./pages/SuccessPage'));

function App() {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(true);

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-7CXQZ7N9PE');
    ReactGA.send('pageview');

    // Debug environment variables
    // console.log("API Base URL:", process.env.REACT_APP_API_BASE_URL);
  }, []);

  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  return (
    <AuthProvider> {/* Wrap the app in AuthProvider */}
      <Router>
        <Suspense fallback={<LoadingSpinner />}>
          <div className="flex flex-col min-h-screen">
            <Helmet>
              <title>MonChart - Customizable Charts & Analytics</title>
            </Helmet>

            <Header toggleSideMenu={toggleSideMenu} isSideMenuOpen={isSideMenuOpen} />
            <div className="flex flex-row flex-1">
              {isSideMenuOpen && <SideMenu />}
              <main className="flex-1 p-4">
                <Routes>
                  {/* Define all the main routes */}
                  <Route path="/" element={<Homepage />} />
                  <Route path="/login" element={<LoginSignup />} />
                  <Route path="/signup" element={<LoginSignup />} />
                  <Route path="/pricing" element={<TrialPage />} />
                  <Route path="/support" element={<SupportPage />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/terms-of-use" element={<TermsOfUse />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/upload" element={<UploadPage />} />
                  <Route path="/clean" element={<CleanDataPage />} />
                  <Route path="/cancel/:reportattemptid" element={<CancelPage />} />
                  <Route path="/success/:reportsuccessid" element={<SuccessPage />} />

                  {/* Catch-all for 404 */}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </main>
            </div>
            <Footer />
            <CookieConsent />
          </div>
        </Suspense>
      </Router>
    </AuthProvider>
  );
}

export default App;
